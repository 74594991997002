<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <!--（表单）选项区域-->
        <el-form
          ref="form"
          :model="formInline"
          :inline="true"
          label-position="right"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="修改原因:">
                <el-select v-model="formInline.orderAppealType" placeholder="请选择">
                  <el-option label="全部" value></el-option>
                  <el-option
                    v-for="item in orderAppealTypeList"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="处理记录ID:">
                <el-input
                  v-model="formInline.orderAppealRecordId"
                  placeholder="请输入"
                  :onkeyup="
                    (formInline.orderAppealRecordId = formInline.orderAppealRecordId.replace(
                      /[^\d]/g,
                      ''
                    ))
                  "
                  maxlength="19"
                ></el-input>
              </el-form-item>
              <el-form-item label="业务订单类型:">
                <el-select v-model="formInline.businessOrderType" placeholder="请选择">
                  <el-option label="全部" value></el-option>
                  <el-option
                    v-for="item in businessOrderTypeList"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="业务订单ID:">
                <el-input
                  v-model="formInline.businessRecordId"
                  :onkeyup="
                    (formInline.businessRecordId = formInline.businessRecordId.replace(
                      /[^\d]/g,
                      ''
                    ))
                  "
                  placeholder="请输入"
                  maxlength="19"
                ></el-input>
              </el-form-item>
              <!--第二行-->
              <el-form-item label="确认时间:">
                <timeRangePick
                  @timeChange="timeChangeQR"
                  ref="timeRangePickerQR"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item label="发起时间:">
                <timeRangePick
                  @timeChange="timeChangeFQ"
                  ref="timeRangePickerFQ"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item label="状态:">
                <el-select v-model="formInline.status" placeholder="请选择">
                  <el-option label="全部" value></el-option>
                  <el-option
                    v-for="item in statusList"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
                <el-autocomplete
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsyncOrder"
                  placeholder="车牌号"
                  :maxlength="8"
                  :trigger-on-focus="false"
                  @select="handleSelectOrder"
                ></el-autocomplete>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button type="info" icon="el-icon-upload2" @click="exportHandler">{{ $t('button.export') }}</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表-->
      <div class="tableWrapper bgFFF paddingB20">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column :label="$t('list.index')" align="center" type="index" width="50"></el-table-column>
          <el-table-column
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
            <template slot-scope="scope">
              <span
                v-if="item.prop == 'orderAppealRecordId'"
                v-clipboard:copy="scope.row.orderAppealRecordId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p v-html="item.formatter(scope.row)"></p>
              </span>
              <span
                v-else-if="item.prop == 'businessRecordId'"
                v-clipboard:copy="scope.row.businessRecordId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p>{{ scope.row.businessRecordId }}</p>
              </span>
              <span v-else>
                <p v-html="item.formatter ? item.formatter(scope.row) : scope.row[item.prop]"></p>
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('searchModule.state')" fixed="right" width>
            <template slot-scope="scope">
              <p>{{ scope.row.statusName }}</p>
              <p v-if="retryOrderProcessRecordId === scope.row.orderProcessRecordId">
                重试中
                <span class="dotloading">...</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" width>
            <template slot-scope="scope">
              <el-button type="text" @click="retry(scope)" v-if="scope.row.processStatus == 0"
                >重试</el-button
              >
              <el-button type="text" @click="seeDetails(scope)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
      <detailsDialog
        :dialogVisibleDetails="dialogVisibleDetails"
        @consoleDetails="consoleDetails"
        :orderProcessRecordId="orderProcessRecordId"
        v-if="dialogVisibleDetails"
      ></detailsDialog>
    </div>

    <export-download
      :defalutDate="exportTime"
      :exprotDialog="exprotDialog"
      :exportForm="exportForm"
      :timeStrTitle="timeStrTitle"
      :businessList="businessList"
      @exportFlag="exportFlag"
      @closeFlag="exprotDialog = false"
    >
    </export-download>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import detailsDialog from "./componets/detailsDialog";
import ExportDownload from "@/components/uploadFile/exportDownload";
import { setIndex, dateFormat, exportExcelNew } from "@/common/js/public.js";
export default {
  name: "examine",
  data() {
    return {
      dialogVisibleDetails: false,
      defalutDate: [],
      pageNum: 1,
      total: 0,
      pageSize: 15,
      loading: false,
      tableData: [],
      exprotDialog: false, // 导出弹窗
      businessList: [], // 订单列表
      timeStrTitle: "发起时间",
      exportForm: {
        businessType: 0,
      },
      statusList: [
        {
          code: "1",
          desc: "处理完成",
        },
        {
          code: "0",
          desc: "处理中",
        },
        {
          code: "2",
          desc: "处理失败",
        },
      ],
      orderAppealTypeList: [
        {
          code: "11",
          desc: "异常订单处理",
        },
        {
          code: "12",
          desc: "自动平单退款",
        },
      ],
      businessOrderTypeList: [],
      confirmUserIdList: [],
      formInline: {
        status: "",
        orderAppealType: "",
        businessOrderType: "",
        businessRecordId: "",
        orderAppealRecordId: "",
        confirmUserId: "",
        appealConfirmTimeStart: "", // 确认时间--审核开始
        appealConfirmTimeEnd: "",
        appealCreatedTimeStart: "", // 发起时间 ---申诉处理开始时间
        appealCreatedTimeEnd: "",
        plateNumber: "",
      },
      tableCols: [
        {
          prop: "appealTypeName",
          label: "修改原因",
        },
        {
          prop: "orderAppealRecordId",
          label: "处理记录ID",
          formatter: (row, column) => {
            if (row.orderAppealRecordId != 0) {
              return row.orderAppealRecordId;
            }
          },
        },
        {
          prop: "businessTypeName",
          label: "业务订单类型",
        },
        {
          prop: "businessRecordId",
          label: "业务订单ID",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "110",
        },
        {
          prop: "originalShouldPayMoney",
          label: "订单金额(元)",
          formatter: (row, column) => {
            if (row.originalShouldPayMoney && row.modifyShouldPayMoney) {
              let y = Number(row.originalShouldPayMoney / 100).toFixed(2);
              let x = Number(row.modifyShouldPayMoney / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              return x == y ? `<span margin-left:10px;">${y}</span>` : data;
            } else {
              let y = Number(row.originalShouldPayMoney / 100).toFixed(2);
              let s = Number(row.modifyShouldPayMoney / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${s}</span>`;
              return s == y ? `<span margin-left:10px;">${y}</span>` : data;
            }
          },
        },
        {
          prop: "originalAgioPayMoney",
          label: "优惠总计(元)",
          formatter: (row, column) => {
            if (row.originalAgioPayMoney && row.modifyAgioPayMoney) {
              let y = Number(row.originalAgioPayMoney / 100).toFixed(2);
              let x = Number(row.modifyAgioPayMoney / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              return x == y ? `<span margin-left:10px;">${y}</span>` : data;
            } else {
              let y = Number(row.originalAgioPayMoney / 100).toFixed(2);
              let s = Number(row.modifyAgioPayMoney / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${s}</span>`;
              return s == y ? `<span margin-left:10px;">${y}</span>` : data;
            }
          },
        },
        {
          prop: "modifyActualPayMoney",
          label: "实付总计(元)",
          formatter: (row, column) => {
            if (row.originalActualPayMoney && row.modifyActualPayMoney) {
              let y = Number(row.originalActualPayMoney / 100).toFixed(2);
              let x = Number(row.modifyActualPayMoney / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              return x == y ? `<span margin-left:10px;">${y}</span>` : data;
            } else {
              let y = Number(row.originalActualPayMoney / 100).toFixed(2);
              let s = Number(row.modifyActualPayMoney / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${s}</span>`;
              return s == y ? `<span margin-left:10px;">${y}</span>` : data;
            }
          },
        },
        {
          prop: "originalLackMoney",
          label: "欠费总计(元)",
          formatter: (row, column) => {
            if (row.originalLackMoney && row.modifyLackMoney) {
              let y = Number(row.originalLackMoney / 100).toFixed(2);
              let x = Number(row.modifyLackMoney / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              return x == y ? `<span margin-left:10px;">${y}</span>` : data;
            } else {
              let y = Number(row.originalLackMoney / 100).toFixed(2);
              let s = Number(row.modifyLackMoney / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${s}</span>`;
              return s == y ? `<span margin-left:10px;">${y}</span>` : data;
            }
          },
        },
        {
          prop: "appealConfirmTime",
          label: "确认时间",
        },
        {
          prop: "appealCreatedTime",
          label: "发起时间",
        },
      ],
      orderProcessRecordId: "", // 处理明细ID
      retryOrderProcessRecordId: "", // 重试中的ID
    };
  },
  watch: {},
  methods: {
    // 申诉记录处理状态枚举值
    getStatusList() {
      let opt = {
        url: "/acb/2.0/orderAppeal/appealDealStatusEnum",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.statusList = res.value;
          } else {
            this.$alert("获取申诉记录处理状态枚举值失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 申诉记录处理类型枚举值 --- 修改原因
    // getOrderAppealTypeList() {
    //   let opt = {
    //     url: "/acb/2.0/orderAppeal/appealTypeEnum",
    //     method: "get",
    //     data: {},
    //     success: res => {
    //       if (res.state == 0) {
    //         this.orderAppealTypeList = res.value;
    //       } else {
    //         this.$alert("获取申诉记录处理类型枚举值失败", this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine')
    //         });
    //       }
    //     }
    //   };
    //   this.$request(opt);
    // },
    // 业务订单类型枚举值 --- 修改原因
    getBusinessOrderTypeList() {
      let opt = {
        url: "/acb/2.0/orderAppeal/businessOrderTypeEnum",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.businessOrderTypeList = res.value;
          } else {
            this.$alert("获取业务订单类型枚举值失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    resetForm() {
      this.formInline = {
        status: "",
        orderAppealType: "",
        businessOrderType: "",
        businessRecordId: "",
        orderAppealRecordId: "",
        confirmUserId: "",
        appealConfirmTimeStart: "",
        appealConfirmTimeEnd: "",
        appealCreatedTimeStart: "",
        appealCreatedTimeEnd: "",
        carId: "",
      };
      this.$refs.timeRangePickerQR.resetTime();
      this.$refs.timeRangePickerFQ.resetTime();
      this.formInline.plateNumber = "";
      this.pageNum = 1;
      this.searParkRecordList();
    },

    /**
     * 导出
     */
    exportHandler() {
      this.exprotDialog = true;
    },

    exportFlag(val) {
      if (val) {
        let data = {
          businessOrderType: val.businessType,
          appealCreatedTimeStart: val.startTime,
          appealCreatedTimeEnd: val.endTime,
          ...this.formInline,
        };
        // exportExcelNew('/acb/2.0/orderProcess/record/export', data, 'get');
        this.$axios
          .get("/acb/2.0/orderProcess/record/export", {
            data,
          })
          .then((res) => {
            if (res.state === 0) {
              this.$message({
                type: "success",
                message: "成功添加下载列表！",
              });
              this.exprotDialog = false;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
    },

    /**
     * 业务订单类型
     */
    getOrderAppeal() {
      let opt = {
        method: "get",
        url: "/acb/2.0/orderAppeal/businessOrderTypeEnum",
        data: {},
        success: (res) => {
          this.businessList = res.value;
        },
      };
      this.$request(opt);
    },

    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChangeQR(timeArr) {
      this.formInline.appealConfirmTimeStart = timeArr[0];
      this.formInline.appealConfirmTimeEnd = timeArr[1];
    },
    timeChangeFQ(timeArr) {
      this.formInline.appealCreatedTimeStart = timeArr[0];
      this.formInline.appealCreatedTimeEnd = timeArr[1];
      this.exportTime = timeArr;
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    // 重试
    retry(scope) {
      this.retryOrderProcessRecordId = scope.row.orderProcessRecordId;
      let opt = {
        method: "post",
        url: "/acb/2.0/orderProcess/refundRetry",
        data: {
          orderProcessRecordId: scope.row.orderProcessRecordId,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            console.log("重试成功");
            this.searParkRecordList();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 查看详情
    seeDetails(scope) {
      this.orderProcessRecordId = scope.row.orderProcessRecordId;
      this.dialogVisibleDetails = true;
    },
    // 关闭查看
    consoleDetails() {
      this.dialogVisibleDetails = false;
    },
    // 搜索
    searParkRecordList() {
      if (this.formInline.appealConfirmTimeStart || this.formInline.appealCreatedTimeEnd) {
        this.searParkRecordListFun();
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
      // if (this.formInline.appealCreatedTimeStart && this.formInline.appealCreatedTimeEnd) {
      //   this.searParkRecordListFun()
      // } else {
      //   return false
      //   this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine')
      //   });
      // }
      // let flag = this.showLog(
      //   "确认时间：",
      //   this.formInline.appealConfirmTimeStart,
      //   this.formInline.appealConfirmTimeEnd,
      //   (arr) => {
      //     console.log(1111)
      //     return this.searParkRecordListFun()
      //   }
      // );
      // let flagFQ = this.showLog(
      //   "发起时间：",
      //   this.formInline.appealCreatedTimeStart,
      //   this.formInline.appealCreatedTimeEnd,
      //   (arr) => {
      //     console.log(1111)
      //     return this.searParkRecordListFun()
      //   }
      // );
      // if (flag && flagFQ) {
      //   this.searParkRecordListFun();
      // }
      //   this.searParkRecordListFun();
    },

    handleSelectOrder(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      //   this.pageNum = 1;
      //   this.searParkRecordList();
    },

    querySearchAsyncOrder(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    // showLog(name, start, end, callback = null) {
    //   if (start && end) {
    //     let time = new Date(end) - new Date(start);
    //     time = time / (24 * 60 * 60 * 1000);
    //     if (time >= 31) {
    //       this.$alert(name + "查询时间不能大于31天");
    //       return false;
    //     } else {
    //       if (callback) {
    //         console.log(222)
    //         callback(true)
    //       }
    //       return true;
    //     }
    //   } else {
    //     this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
    //       confirmButtonText: this.$t('pop_up.Determine')
    //     });
    //     return false;
    //   }
    // },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    searParkRecordListFun() {
      let opt = {
        method: "get",
        url: "/acb/2.0/orderProcess/record/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline,
        },
        success: (res) => {
          this.loading = false;
          this.retryOrderProcessRecordId = ""; // 清除重试ID
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
  },
  created() {
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
    this.exportTime = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
    // this.getStatusList();
    // this.getOrderAppealTypeList();
    this.getBusinessOrderTypeList();
  },
  mounted() {
    this.getOrderAppeal();
    this.searParkRecordListFun();
  },
  components: {
    timeRangePick,
    detailsDialog,
    ExportDownload,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">


.tableWrapper {
  background: #fff;
}

.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}

.title {
  margin-top: 30px;
}

.warning {
  color: red;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.titleh1 {
  margin: 10px 10px;
  font-size: 15px;
}

.but {
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.orderNumberStyle {
  color: #0f6eff;
}

.dotloading {
  display: inline-block;
  height: 1em;
  line-height: 1;
  text-align: left;
  vertical-align: -0.25em;
  overflow: hidden;
}

.dotloading::before {
  display: block;
  content: '...\A..\A.';
  white-space: pre-wrap;
  animation: dotloading 3s infinite step-start both;
}

@keyframes dotloading {
  33% {
    transform: translateY(-2em);
  }

  66% {
    transform: translateY(-1em);
  }
}
</style>
