<!--
 * @Author: faf
 * @desc: 导出到下载中心
 * @Date: 2022-09-19 16:25:55
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <el-dialog
    title="下载中心"
    :visible.sync="exprotDialog"
    width="650px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-form :model="exportForm">
      <el-form-item :label="$t('searchModule.Business_order_type')" label-width="120px">
        <el-select v-model="exportForm.businessType" placeholder="请选择">
          <el-option
            v-for="item in businessList"
            :key="item.desc"
            :label="item.desc"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="timeStrTitle" label-width="120px">
        <timeRangePick
          @timeChange="timeChange1"
          ref="timeRangePicker"
          :defalutDate="defalutDate"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="saveHandler">确 定</el-button>
      <el-button @click="exportHandler">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import timeRangePick from "@/components/timePicker";
export default {
  data() {
    return {};
  },
  components: {
    timeRangePick
  },
  computed: {
    exportFlag: function () {
      return false;
    }
  },
  props: {
    exprotDialog: {
      default: function () {
        return false;
      }
    },
    timeStrTitle: {
      default: function () {
        return "";
      }
    },
    exportForm: {
      default: function () {
        return {};
      }
    },
    defalutDate: {
      default: function () {
        return [];
      }
    },
    businessList: {
      default: function () {
        return [];
      }
    }
  },
  methods: {
    timeChange1(timeArr) {
      this.exportForm.startTime = timeArr[0];
      this.exportForm.endTime = timeArr[1];
    },

    exportHandler() {
      this.$emit("closeFlag", false);
    },

    saveHandler() {
      this.$emit("exportFlag", this.exportForm);
    }
  }
};
</script>
<style lang="stylus" rel="stylesheet/stylus" scoped></style>
