<template>
  <div class="processDetails">
    <el-dialog title="修改详情" :visible="dialogVisibleDetails" @close="consoleDetail" width="70%">
      <h2 class="parkingInfo">停车订单: {{ parkRecordVO.parkRecordId }}</h2>
      <!-- 详情 -->
      <div class="carDetail">
        <h2 class="parkingInfo">车场详情</h2>
        <el-row>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">停车场名称：</span>
              <span>{{ parkRecordVO.parkName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">商户名称:</span>
              <span>{{ parkRecordVO.operationName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">车场类型:</span>
              <span>{{ parkRecordVO.parkTypeName }}</span>
            </div>
          </el-col>
          <el-col :span="12" class="detailsLeft">
            <!-- <div class="detailsItem">
              <span class="detailsSpan">城市：</span>
              <span>{{ parkRecordVO.cityName }}</span>
            </div> -->
            <div class="detailsItem">
              <span class="detailsSpan">区域：</span>
              <span>{{ parkRecordVO.areaName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">泊位号：</span>
              <span>{{ parkRecordVO.berthCode }}</span>
            </div>
          </el-col>
        </el-row>
        <h2 class="parkingInfo">车辆详情</h2>
        <el-row>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">车牌号：</span>
              <span>{{ parkRecordVO.plateNumber }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">入场时间：</span>
              <span>{{ parkRecordVO.strEntryTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">入场记录来源：</span>
              <span>{{ parkRecordVO.entryDataSourceName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">证据图像：</span>
              <span class="orderNumberStyle" style="cursor: pointer" @click="lookPicture"
                >查看</span
              >
            </div>
          </el-col>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">停车时长：</span>
              <span>{{ parkRecordVO.parkTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">出场时间：</span>
              <span>{{ parkRecordVO.strExitTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">出场记录来源：</span>
              <span>{{ parkRecordVO.exitDataSourceName }}</span>
            </div>
          </el-col>
        </el-row>
        <h2 class="parkingInfo">订单详情</h2>
        <el-row>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">应收金额：</span>
              <span v-if="payOrderVO.originalShouldPayMoney == payOrderVO.modifyShouldPayMoney">
                {{ payOrderVO.originalShouldPayMoney | moneyFormat }}</span
              >
              <u v-else class="ustyleLine">
                {{ payOrderVO.originalShouldPayMoney | moneyFormat }}</u
              >
              <a
                class="uStyleItem"
                v-if="payOrderVO.originalShouldPayMoney != payOrderVO.modifyShouldPayMoney"
              >
                {{ (payOrderVO.modifyShouldPayMoney / 100).toFixed(2) }} 元</a
              >
            </div>
          </el-col>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">优惠总计：</span>
              <span>
                <span v-if="payOrderVO.originalAgioPayMoney == payOrderVO.modifyAgioPayMoney">
                  {{ payOrderVO.originalAgioPayMoney | moneyFormat }}</span
                >
                <u v-else class="ustyleLine">
                  {{ payOrderVO.originalAgioPayMoney | moneyFormat }}</u
                >
                <a
                  class="uStyleItem"
                  v-if="payOrderVO.originalAgioPayMoney != payOrderVO.modifyAgioPayMoney"
                >
                  {{ (payOrderVO.modifyAgioPayMoney / 100).toFixed(2) }}</a
                >
              </span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">实付总计：</span>
              <span>
                <span v-if="payOrderVO.originalActualPayMoney == payOrderVO.modifyActualPayMoney">
                  {{ payOrderVO.originalActualPayMoney | moneyFormat }}</span
                >
                <u v-else class="ustyleLine">
                  {{ payOrderVO.originalActualPayMoney | moneyFormat }}</u
                >
                <a
                  class="uStyleItem"
                  v-if="payOrderVO.originalActualPayMoney != payOrderVO.modifyActualPayMoney"
                >
                  {{ payOrderVO.modifyActualPayMoney | moneyFormat }}</a
                >
              </span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">欠费总计：</span>
              <span>
                <span v-if="payOrderVO.originalLackMoney == payOrderVO.modifyLackMoney">
                  {{ payOrderVO.originalLackMoney | moneyFormat }}</span
                >
                <u v-else class="ustyleLine"> {{ payOrderVO.originalLackMoney | moneyFormat }}</u>
                <a
                  class="uStyleItem"
                  v-if="payOrderVO.originalLackMoney != payOrderVO.modifyLackMoney"
                >
                  {{ payOrderVO.modifyLackMoney | moneyFormat }}</a
                >
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 修改信息 -->
      <h2 class="parkingInfo">修改信息</h2>
      <el-row>
        <el-col :span="12" class="detailsLeft">
          <div class="detailsItem">
            <span class="detailsSpan">修改原因：</span>
            <span>{{ payOrderVO.appealTypeName }} </span>
            <span
              class="orderNumberStyle"
              style="cursor: pointer"
              @click="lookModify"
              v-if="payOrderVO.orderAppealRecordId != '0'"
              >查看</span
            >
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">修改金额：</span>
            <span>{{ payOrderVO.totalModifyMoney | moneyFormat }}</span>
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">退款方式：</span>
            <span>原路退回</span>
          </div>
        </el-col>
        <el-col :span="12" class="detailsLeft">
          <div class="detailsItem">
            <span class="detailsSpan">欠费金额修改：</span>
            <span>{{ payOrderVO.totalModifyLackMoney | moneyFormat }}</span>
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">优惠金额修改：</span>
            <span>{{ payOrderVO.totalModifyAgioMoney | moneyFormat }}</span>
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">退款金额共计：</span>
            <span>{{ payOrderVO.totalModifyRefundMoney | moneyFormat }}</span>
          </div>
        </el-col>
      </el-row>
      <h2 class="parkingInfo">订单修改明细:</h2>
      <!-- 表格 -->
      <el-table
        border
        
        :cell-style="backgroundColor"
        :data="orderProcessDetailVOList"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          :label="item.label"
          :width="item.width"
          v-for="item in tableDetails"
          :key="item.prop"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <span
              ref="orderNumberStyle"
              v-if="item.prop == 'orderProcessDetailId'"
              v-clipboard:copy="scope.row.orderProcessDetailId"
              v-clipboard:success="onCopy"
              :class="scope.row.equaliseOrderType == 5 ? 'orderNumberStylecor' : 'orderNumberStyle'"
            >
              <p>{{ scope.row.orderProcessDetailId }}</p>
            </span>
            <span
              ref="orderNumberStyle"
              v-else-if="item.prop == 'businessOrderId'"
              v-clipboard:copy="scope.row.businessOrderId"
              v-clipboard:success="onCopy"
              :class="scope.row.equaliseOrderType == 5 ? 'orderNumberStylecor' : 'orderNumberStyle'"
            >
              <p>{{ scope.row.businessOrderId }}</p>
            </span>
            <span v-else>
              <p v-html="item.formatter ? item.formatter(scope.row) : scope.row[item.prop]"></p>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 关闭 -->
      <div class="closewindow">
        <el-button @click="consoleDetail">关闭窗口</el-button>
        <!-- <el-button type="primary" @click="confirmModify">确认修改</el-button> -->
      </div>
    </el-dialog>
    <!-- 修改信息弹框 -->
    <processDetails
      :dialogVisibleDetails="dialogVisibleModify"
      v-if="dialogVisibleModify"
      @consoleDetails="consoleModify"
      :parkRecordVO="parkRecordVO"
      :orderProcessRecordVO="orderProcessRecordVO"
      :payOrderVO="payOrderVO"
    ></processDetails>
    <!-- 证据图像弹框 -->
    <processPicture
      ref="processPictureID"
      :dialogVisible="dialogVisible"
      @consolePicture="consolePicture"
    ></processPicture>
  </div>
</template>
<script>
// import processPicture from "./processPicture";
import processDetails from "./processDetails";

import processPicture from "@/components/exceptionHanding/processPicture";
export default {
  name: "picDetail",
  data() {
    return {
      datapic1: [],
      datapic2: [],
      payOrderVO: {},
      dialogVisible: false, // 证据图像弹框
      dialogVisibleModify: false, // 修改信息弹框
      appealData: {}, // 修改数据
      parkRecordVO: {}, // 停车记录
      tableDetails: [
        {
          prop: "statusName",
          label: "修改状态",
        },
        {
          prop: "orderCreatedTime",
          label: "订单生成时间",
        },
        {
          prop: "orderPayTypeName",
          label: "支付订单类型",
        },
        {
          prop: "payChannelName",
          label: this.$t("list.Payment_channels"),
        },
        {
          prop: "businessOrderId",
          label: "支付订单ID",
        },
        {
          prop: "deductionModifyMoney",
          label: "抵扣修改金额",
          formatter: (row, column) => {
            if (row.deductionModifyMoney) {
              return Number(row.deductionModifyMoney / 100).toFixed(2) + "元";
            } else {
              return "0.00元";
            }
          },
        },
        {
          prop: "originalAgioPayMoney",
          label: this.$t("list.preferential_amount"),
          width: "120",
          formatter: (row, column) => {
            if (row.originalAgioPayMoney && row.modifyAgioPayMoney) {
              let y = Number(row.originalAgioPayMoney / 100).toFixed(2);
              let x = Number(row.modifyAgioPayMoney / 100).toFixed(2);
              let data = `<s>${y}元</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              return x == y ? `<span margin-left:10px;">${y}</span>` : data;
              // return `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
            } else {
              let y = Number(row.originalAgioPayMoney / 100).toFixed(2);
              let x = Number(row.modifyAgioPayMoney / 100).toFixed(2);
              let data = `<s>${y}元</s><span style="color:#0f6eff;margin-left:10px;">${x}元</span>`;
              return x == y ? `<span margin-left:10px;">${y}元</span>` : data;
              // return `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              // return Number(row.originalAgioPayMoney / 100).toFixed(2);
            }
          },
        },
        {
          prop: "originalActualPayMoney",
          label: this.$t("list.Pay_amount"),
          width: "120",
          formatter: (row, column) => {
            if (row.originalActualPayMoney && row.modifyActualPayMoney) {
              let y = Number(row.originalActualPayMoney / 100).toFixed(2);
              let x = Number(row.modifyActualPayMoney / 100).toFixed(2);
              let data = `<s>${y}元</s><span style="color:#0f6eff;margin-left:10px;">${x}元</span>`;
              return x == y ? `<span margin-left:10px;">${y}元</span>` : data;
              // return `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
            } else {
              let y = Number(row.originalActualPayMoney / 100).toFixed(2);
              let x = Number(row.modifyActualPayMoney / 100).toFixed(2);
              let data = `<s>${y}元</s><span style="color:#0f6eff;margin-left:10px;">${x}元</span>`;
              return x == y ? `<span margin-left:10px;">${y}元</span>` : data;
              // return `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              // return Number(row.originalActualPayMoney / 100).toFixed(2);
            }
          },
        },
        {
          prop: "originalLackMoney",
          label: this.$t("list.arrears"),
          formatter: (row, column) => {
            if (row.originalLackMoney && row.modifyLackMoney) {
              let y = Number(row.originalLackMoney / 100).toFixed(2);
              let x = Number(row.modifyLackMoney / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              return x == y ? `<span margin-left:10px;">${y}</span>` : data;
              // return `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
            } else {
              let y = Number(row.originalLackMoney / 100).toFixed(2);
              let x = Number(row.modifyLackMoney / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              return x == y ? `<span margin-left:10px;">${y}</span>` : data;
              // return `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              // return Number(row.originalLackMoney / 100).toFixed(2);
            }
          },
        },
        {
          prop: "needRefundMoney",
          label: "需要退款金额",
          formatter: (row, column) => {
            if (row.needRefundMoney) {
              return Number(row.needRefundMoney / 100).toFixed(2) + "元";
            } else {
              return "0.00元";
            }
          },
        },
      ],
      orderProcessDetailVOList: [],
      orderProcessRecordVO: {},
    };
  },
  watch: {},
  filters: {
    moneyFormat(money) {
      if (money) {
        return Number(money / 100).toFixed(2) + "元";
      } else {
        return "0.00元";
      }
    },
  },
  components: {
    // processPicture,
    processDetails,
    processPicture,
  },
  mounted() {},
  computed: {},
  methods: {
    backgroundColor({ row, column, rowIndex, columnIndex }) {
      console.log(column);
      if (row.equaliseOrderType == 5) {
        return {
          color: "#CCCCCC",
        };
      }
    },
    // 查看证据图片
    lookPicture() {
      this.dialogVisible = true;
      this.$refs.processPictureID.getPic(
        this.parkRecordVO.entryOprNum,
        this.parkRecordVO.exitOprNum
      );
    },
    consolePicture() {
      this.dialogVisible = false;
    },
    // 查看修改信息
    lookModify() {
      this.dialogVisibleModify = true;
    },
    consoleModify(obj) {
      console.log(obj);
      this.appealData = obj;
      this.dialogVisibleModify = false;
    },
    consoleDetail() {
      this.$emit("consoleDetails");
      // this.$emit('update:visible', false)
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    confirmModify() {
      let opt = {
        method: "post",
        url: "/acb/2.0/orderAppeal/add",
        data: {
          ...this.appealData,
        },
        success: (res) => {
          if (res.state == 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 订单修改明细详情查询
    searParkRecordListFun() {
      let opt = {
        method: "get",
        url: "/acb/2.0/orderProcess/record/detail",
        data: {
          orderProcessRecordId: this.orderProcessRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.parkRecordVO = res.value.parkRecordVO;
            this.payOrderVO = res.value.orderProcessRecordVO;
            this.orderProcessDetailVOList = res.value.orderProcessDetailVOList;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
  },
  props: {
    dialogVisibleDetails: {
      default: false,
    },
    orderProcessRecordId: {
      type: String,
      default: "",
    },
  },
  created() {
    this.searParkRecordListFun();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.timelineStyle {
  padding-left: 150px;
}

.picBox {
  width: 200px;
  height: 150px;
  padding: 5px;
}

.bottomLine {
  border-bottom: 1px solid #F4F6FA;
}

.stateTip {
  z-index: 9999;
  background-color: #F4F6FA;
  width: 80px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #0f6eff;
  border-radius: 10px;
  font-weight: 700;
}

.detailsItem {
  margin-bottom: 10px;
  padding-left: 30px;
}

.detailsSpan {
  width: 150px;
  text-align: left;
  display: inline-block;
}

.orderNumberStyle {
  color: #0f6eff;
}

.closewindow {
  display: flex;
  justify-content: right;
  margin-top: 10px;
}

.parkingInfo {
  font-family: 'PingFang';
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  border-bottom: 1px solid #DCDFE6;
}

.parkingInfo-on {
  position: absolute;
  left: 0;
  top: 31%;
  width: 4px;
  height: 16px;
  background: #0099fa;
}

.ustyleLine {
  text-decoration: line-through;
}

.uStyleItem {
  color: #0f6eff;
}
</style>
<style lang="stylus">
.processDetails .el-dialog__body {
  padding-top: 4px;
}

.processDetails .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}

.processDetails .el-step.is-center .el-step__line {
  left: 50%;
  right: -63%;
}

.carDetail {
  padding: 0 20px;
}
.orderNumberStylecor {
  color: #cccccc
}
</style>
