<template>
  <div class="processDetails">
    <el-dialog
      title="异常订单处理记录审核"
      :visible="dialogVisibleDetails"
      @close="consoleDetail"
      width="70%"
    >
      <h2 class="parkingInfo">停车订单列表</h2>
      <!-- 表格 -->
      <el-table  :data="tableData" style="width: 100%">
        <el-table-column
          align="center"
          :label="item.label"
          :width="item.width"
          v-for="item in tableDetails"
          :key="item.prop"
        >
          <template slot-scope="scope">
            <span
              v-if="item.prop == 'parkRecordId'"
              v-clipboard:copy="scope.row.parkRecordId"
              v-clipboard:success="onCopy"
              class="orderNumberStyle"
            >
              <p :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                {{ scope.row.parkRecordId }}
              </p>
            </span>
            <span v-else>
              <p :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}
              </p>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 详情 -->
      <div class="carDetail">
        <h2 class="parkingInfo">车场详情</h2>
        <el-row>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">停车场名称：</span>
              <span>{{ parkRecordVO.parkName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">商户名称:</span>
              <span>{{ parkRecordVO.operationName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">车场类型:</span>
              <span>{{ parkRecordVO.parkTypeName }}</span>
            </div>
          </el-col>
          <el-col :span="12" class="detailsLeft">
            <!-- <div class="detailsItem">
              <span class="detailsSpan">城市：</span>
              <span>{{ parkRecordVO.cityName }}</span>
            </div> -->
            <div class="detailsItem">
              <span class="detailsSpan">区域：</span>
              <span>{{ parkRecordVO.areaName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">泊位号：</span>
              <span>{{ parkRecordVO.berthCode }}</span>
            </div>
          </el-col>
        </el-row>
        <h2 class="parkingInfo">车辆详情</h2>
        <el-row>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">车牌号：</span>
              <span>{{ parkRecordVO.plateNumber }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">入场时间：</span>
              <span>{{ parkRecordVO.strEntryTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">入场记录来源：</span>
              <span>{{ parkRecordVO.entryDataSourceName }}</span>
            </div>
          </el-col>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">停车时长：</span>
              <span>{{ parkRecordVO.parkTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">出场时间：</span>
              <span>{{ parkRecordVO.strExitTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">出场记录来源：</span>
              <span>{{ parkRecordVO.exitDataSourceName }}</span>
            </div>
          </el-col>
        </el-row>
        <h2 class="parkingInfo">订单详情</h2>
        <el-row>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">应收金额：</span>
              <span>
                <u class="ustyleLine"
                  >{{
                    ordermoney.originSumShouldPay
                      ? (ordermoney.originSumShouldPay / 100).toFixed(2)
                      : "0.00"
                  }}元
                </u>
                <a class="uStyleItem"
                  >{{
                    ordermoney.modifySumShouldPay
                      ? (ordermoney.modifySumShouldPay / 100).toFixed(2)
                      : "0.00"
                  }}元</a
                >
              </span>
            </div>
          </el-col>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">优惠总计：</span>
              <span>
                <u class="ustyleLine"
                  >{{
                    ordermoney.originSumAgioMoney
                      ? (ordermoney.originSumAgioMoney / 100).toFixed(2)
                      : "0.00"
                  }}元
                </u>
                <a class="uStyleItem"
                  >{{
                    ordermoney.modifySumAgioMoney
                      ? (ordermoney.modifySumAgioMoney / 100).toFixed(2)
                      : "0.00"
                  }}元</a
                >
              </span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">实付总计：</span>
              <span>
                <u class="ustyleLine"
                  >{{
                    ordermoney.originSumActualMoney
                      ? (ordermoney.originSumActualMoney / 100).toFixed(2)
                      : "0.00"
                  }}元
                </u>
                <a class="uStyleItem"
                  >{{
                    ordermoney.modifySumActualMoney
                      ? (ordermoney.modifySumActualMoney / 100).toFixed(2)
                      : "0.00"
                  }}元</a
                >
              </span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">欠费总计：</span>
              <span>
                <u class="ustyleLine"
                  >{{
                    ordermoney.originSumLackMoney
                      ? (ordermoney.originSumLackMoney / 100).toFixed(2)
                      : "0.00"
                  }}元
                </u>
                <a class="uStyleItem"
                  >{{
                    ordermoney.modifySumLackMoney
                      ? (ordermoney.modifySumLackMoney / 100).toFixed(2)
                      : "0.00"
                  }}元</a
                >
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 流程 -->
      <div>
        <div class="parkingInfo bottomLine">
          <b>流程查看:</b>
          <span
            class="orderNumberStyle"
            style="padding-left: 10px; cursor: pointer"
            @click="lookAll"
            >{{ lookAllTag ? "返回" : "查看全部" }}</span
          >
        </div>
        <el-steps :active="status" align-center>
          <el-step title="发起处理" description>
            <template slot="description">
              <p>{{ ordermoney.handleOperatorName }} {{ ordermoney.handleTime }}</p>
            </template>
          </el-step>
          <span class="stateTip">已完成</span>
          <el-step title="申诉审核" description>
            <template slot="description">
              <p>{{ ordermoney.handleOperatorName }} {{ ordermoney.appealTime }}</p>
            </template>
          </el-step>
        </el-steps>
      </div>
      <!-- 流程记录 -->
      <div v-show="lookAllTag">
        <div class="parkingInfo bottomLine">
          <span>流程记录:</span>
        </div>
        <!-- <el-timeline class="timelinediy">
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :timestamp="activity.timestamp"
            placement="top"
          >
            <div class="toExamine">申诉处理审核</div>
            <div class="Arraignment">申诉处理提审</div>
            <div class="y-time">{{ activity.ytimestamp }}</div>
            <div class="t-title">{{ activity.content }}</div>
            <div v-if="index === 0">
              <el-row>
                <el-col :span="8">来源:平台</el-col>
                <el-col :span="8">操作人:{{ordermoney.appealOperatorName}}</el-col>
              </el-row>
            </div>
            <div v-if="index === 1">
              <el-row>
                <el-col :span="8">操作项</el-col>
                <el-col :span="8">初始值</el-col>
                <el-col :span="8">变更值</el-col>
              </el-row>
              <el-row>
                <el-col :span="8">{{ordermoney.appealTypeName}}</el-col>
                <el-col :span="8">{{ordermoney.originValue}}</el-col>
                <el-col :span="8">{{ordermoney.currentValue}}</el-col>
              </el-row>
            </div>
          </el-timeline-item>
        </el-timeline>-->
        <div class="timelineStyle" v-if="ordermoney.status == 1">
          <div class="timeLeft">{{ ordermoney.handleTime }}</div>
          <div class="timelineDou">
            <p>申诉处理审核: {{ ordermoney.status == 1 ? "通过" : "拒绝" }}</p>
            <div class="timelineContent">
              <span>15:00:00</span>
              <span>来源</span>
              <span>操作人:{{ ordermoney.appealOperatorName }}</span>
            </div>
            <!-- <div class="timelineContent">
              <span>添加/更改出场时间</span>
              <span>yyyy-mm-dd hh:mm:ss</span>
              <span>yyyy-mm-dd hh:mm:ss</span>
            </div>-->
          </div>
        </div>
        <div class="timelineStyle">
          <div class="timeLeft">{{ ordermoney.appealTime }}</div>
          <div class="timelineDou">
            <p>申诉处理提审</p>
            <div class="timelineContent">
              <span>操作项</span>
              <span>初始值</span>
              <span>变更值</span>
            </div>
            <div class="timelineContent">
              <span>{{ ordermoney.appealTypeName }}</span>
              <span>{{ ordermoney.originValue }}</span>
              <span>{{ ordermoney.currentValue }}</span>
            </div>
          </div>
        </div>
      </div>
      <!--异常停车处理信息 -->
      <el-form ref="formData" :model="formData" :rules="rules">
        <div v-show="!lookAllTag">
          <div class="parkingInfo bottomLine">
            <b>异常停车处理信息:</b>
          </div>
          <el-form-item label="处理理由：" prop="handleReason">
            <el-input
              disabled
              type="textarea"
              placeholder="请输入内容"
              v-model="formData.handleReason"
              maxlength="200"
              show-word-limit
              :rows="4"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 处理方式 -->
        <div v-show="!lookAllTag">
          <div class="parkingInfo bottomLine">
            <span>处理方式:</span>
          </div>
          <el-row>
            <el-col :span="7">
              <el-form-item label="更改项:">
                <el-select v-model="formData.treatmentVlaue" placeholder="请选择" :disabled="true">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10" v-if="false">
              更改为:
              <el-date-picker v-model="timeStr" type="datetime" placeholder="选择日期时间">
              </el-date-picker>
            </el-col>
            <el-col :span="7" v-if="formData.treatmentVlaue == 1">
              调整基于:
              <el-select v-model="timeChange" placeholder="请选择" :disabled="true">
                <el-option
                  v-for="item in timeChangeoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8" v-if="formData.treatmentVlaue == 1 && timeChange == 2">
              调整为: 入场时间 +
              <el-input
                placeholder="请输入"
                v-model="timeInput"
                style="width: 100px"
                :disabled="true"
              >
              </el-input>
              秒
            </el-col>
            <el-col :span="8" v-if="formData.treatmentVlaue == 1 && timeChange == 1">
              调整为:
              <el-date-picker
                v-model="timeStr"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间"
                :disabled="true"
              >
              </el-date-picker>
            </el-col>
            <el-col style="display: flex" :span="12" v-if="formData.treatmentVlaue == 2">
              <div style="height: 100%; line-height: 40px">车牌号:</div>
              <el-select disabled v-model="carColor" placeholder="请选择" style="width: 80px">
                <el-option
                  disabled
                  v-for="item in optionsColor"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-form-item label="" prop="plateNumber">
                <el-input
                  v-model="formData.plateNumber"
                  placeholder="请输入内容"
                  style="width: 180px"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" v-if="formData.treatmentVlaue == 3">
              增/减:
              <el-select v-model="optionsAddItem" placeholder="请选择" style="width: 80px" disabled>
                <el-option
                  v-for="item in optionsAdd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6" v-if="formData.treatmentVlaue == 3">
              <el-form-item label="金额-" prop="discountAmount">
                <el-input
                  disabled
                  v-model="formData.discountAmount"
                  placeholder="请输入内容"
                  style="width: 180px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <h2 class="parkingInfo">审核理由：</h2>
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="formData.appealReason"
            maxlength="30"
            show-word-limit
            disabled
          >
          </el-input>
        </div>
      </el-form>
      <!-- 关闭 -->
      <div class="closewindow">
        <el-button v-show="lookAllTag" @click="lookAll">返回</el-button>
        <el-button @click="consoleDetail">关闭窗口</el-button>
      </div>
    </el-dialog>
    <transition name="fade">
      <div
        class="mask"
        v-show="bigPic"
        :style="{ 'z-index': '10000', 'background-image': bigUrl }"
        @click="bigPic = false"
      ></div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "picDetail",
  data() {
    const originalPriceRule = (rule, value, callback) => {
      if (!/^[0-9]+(.[0-9]{1,2})?$/.test(value)) {
        callback(new Error("请输入大于等于0的数字,最多两位小数"));
      } else {
        callback();
      }
    };
    return {
      appealReason: "",
      rules: {
        handleReason: [{ required: true, message: "请填写处理理由", trigger: "blur" }],
        appealReason: [{ required: true, message: "请填写审核理由", trigger: "blur" }],
        treatmentVlaue: [{ required: true, message: "请选择活动区域", trigger: "change" }],
        discountAmount: [
          {
            required: true,
            message: "请输入金额",
            trigger: "blur",
          },
          {
            validator: originalPriceRule,
            trigger: "blur",
          },
        ],
      },
      timeStr: "",
      timeChange: "1",
      optionsAddItem: "2",
      bigPic: false,
      bigUrl: "",
      lookAllTag: false,
      timeType: "1", // 调整基于 1、具体时间日期 2、记录入场时间
      timeSecond: "", // s秒
      timeDate: "2020-02-02", // yyyy-mm-dd
      carColor: "", // 车牌颜色
      carId: "", // 车牌号
      addsubType: "2", // 金额加减类型
      formData: {
        treatmentVlaue: "1",
        discountAmount: "",
        handleReason: "", // 处理理由
        appealReason: "", // 审核理由
      },
      ordermoney: {},
      // 更改项类型
      options: [
        {
          value: "1",
          label: "调整出场时间",
        },
        {
          value: "2",
          label: "变更车牌号",
        },
        {
          value: "3",
          label: "更改订单金额",
        },
        {
          value: "4",
          label: "免费该订单",
        },
        {
          value: "5",
          label: "作废该订单",
        },
        {
          value: "6",
          label: "仅退款",
        },
      ],
      // 调整基于 列表类型
      timeChangeoptions: [
        {
          value: "1",
          label: "具体时间日期",
        },
        {
          value: "2",
          label: "记录入场时间",
        },
      ],
      // 车牌颜色
      optionsColor: [
        {
          value: "0",
          label: "蓝",
        },
        {
          value: "1",
          label: "黄",
        },
        {
          value: "2",
          label: "绿",
        },
        {
          value: "3",
          label: "黄绿",
        },
        {
          value: "4",
          label: "黑",
        },
        {
          value: "5",
          label: "白",
        },
      ],
      // 金额加减类型
      optionsAdd: [
        {
          value: "1",
          label: "加",
        },
        {
          value: "2",
          label: "减",
        },
      ],
      activities: [
        {
          ytimestamp: "2018-04-12 星期二",
          timestamp: "15:00:00",
        },
        {
          ytimestamp: "2018-04-12 星期二",
          timestamp: "15:00:00",
        },
      ],
      panoramicPhotos: [
        {
          picUrl:
            "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
        },
        {
          picUrl:
            "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
        },
        {
          picUrl:
            "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
        },
      ],
      tableDetails: [
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_Order_ID"),
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
        },
        {
          prop: "strExitTime",
          label: this.$t("list.Appearance_time"),
        },
        {
          prop: "shouldPay",
          label: "应收金额(元)",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2) + "元";
            } else {
              return "0.00元";
            }
          },
        },
        {
          prop: "lackMoney",
          label: "欠费金额(元)",
          formatter: (row, column) => {
            if (row.lackMoney) {
              return Number(row.lackMoney / 100).toFixed(2) + "元";
            } else {
              return "0.00元";
            }
          },
        },
      ],
      tableData: [this.parkRecordVO],
      orderAppealVO: {
        handleReason: "未缴费离场", // 处理理由
        appealReason: "国庆期间免费七天", // 审核理由
      },
      status: null,
    };
  },
  watch: {
    parkRecordIdData: {
      handler(curVal, oldVal) {},
      deep: true,
    },
  },
  components: {},
  mounted() {
    // this.searParkRecordListFun();
  },
  computed: {},
  methods: {
    showBigPic(url) {
      // if (!url) {
      //   return false;
      // }
      this.bigUrl = `url("${url}")`;
      this.bigPic = true;
    },
    lookAll() {
      this.lookAllTag = !this.lookAllTag;
    },
    consoleDetail() {
      console.log(this.orderAppealVO);
      let obj = {
        parkRecordId: this.parkRecordVO.parkRecordId,
        handleReason: this.formData.handleReason,
        // appealType: this.orderAppealVO.appealType,
        appealContent: {},
      };
      this.$emit("consoleDetails", obj);
      // this.$emit('update:visible', false)
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },

    // 申诉记录详情查询
    searParkRecordListFun() {
      let opt = {
        method: "get",
        url: "/acb/2.0/orderAppeal/detail",
        data: {
          parkOrderAppealId: this.payOrderVO.orderAppealRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.orderAppealVO = res.value.orderAppealVO;
            this.ordermoney = res.value;
            this.formData.appealReason = res.value.appealReason;
            this.formData.handleReason = res.value.handleReason;
            this.status = res.value.status;
            let appealContent = JSON.parse(res.value.appealContent);
            this.formData.treatmentVlaue = res.value.appealType + 1 + "";
            if (this.formData.treatmentVlaue == 1) {
              // 出场时间
              this.timeChange = appealContent.exitTimeModifyType + 1 + "";
              if (this.timeChange == 1) {
                this.timeStr = appealContent.modifyExitTime;
              } else {
                this.timeInput = appealContent.exitTimeModifyValue;
              }
            } else if (this.formData.treatmentVlaue == 2) {
              let carNum = res.value.currentValue.split(":");
              this.formData.plateNumber = carNum[1];
              this.carColor = this.getCarColorValue(carNum[0]);
            } else if (this.formData.treatmentVlaue == 3) {
              this.formData.discountAmount = appealContent.diffValue / 100;
              this.tableData[0].modifySumShouldPay = appealContent.modifyShouldPay;
            } else if (this.formData.treatmentVlaue == 4) {
            } else if (this.formData.treatmentVlaue == 5) {
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    getCarColorValue(carColorStr) {
      let color = this.optionsColor.filter((item) => item.label == carColorStr);
      console.log("color--", color);
      return color[0].value;
    },
    submitChange(val) {
      console.log("val", val);
    },
  },
  props: {
    dialogVisibleDetails: {
      default: false,
    },
    // 停车订单信息
    parkRecordVO: {},
    // 订单详情信息
    orderProcessRecordVO: {},
    payOrderVO: {},
  },
  created() {
    this.searParkRecordListFun();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.timelineStyle {
  display: flex;
  min-height: 100px;
}

.timelineDou {
  border-left: 2px solid #C0C4CC;
  position: relative;
  padding: 0px 0px 0px 20px;
}

.timeLeft {
  padding: 0px 20px 0px 10px;
}

.timelineContent span {
  display: inline-block;
  width: 200px;
  padding-top: 20px;
}

.timelineDou:after {
  content: '';
  position: absolute;
  left: -6px;
  top: 0px;
  width: 10px;
  height: 10px;
  background-color: #0f6eff;
  border-radius: 4px;
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.panoramicPhotos {
  display: flex;
}

.picBox {
  width: 200px;
  height: 150px;
  padding: 5px;
}

.bottomLine {
  border-bottom: 1px solid #F4F6FA;
}

.stateTip {
  z-index: 9999;
  background-color: #F4F6FA;
  width: 80px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #0f6eff;
  border-radius: 10px;
  font-weight: 700;
}

.detailsItem {
  margin-bottom: 10px;
  padding-left: 30px;
}

.detailsSpan {
  width: 150px;
  text-align: left;
  display: inline-block;
}

.orderNumberStyle {
  color: #0f6eff;
}

.closewindow {
  display: flex;
  justify-content: right;
  margin-top: 10px;
}

.parkingInfo {
  font-family: 'PingFang';
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  border-bottom: 1px solid #DCDFE6;
}

.parkingInfo-on {
  position: absolute;
  left: 0;
  top: 31%;
  width: 4px;
  height: 16px;
  background: #0099fa;
}

.ustyleLine {
  text-decoration: line-through;
}

.uStyleItem {
  color: #0f6eff;
}
</style>
<style lang="stylus">
.processDetails .el-dialog__body {
  padding-top: 4px;
}

.processDetails .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}

.processDetails .el-step.is-center .el-step__line {
  left: 50%;
  right: -63%;
}

.carDetail {
  padding: 0 20px;
}

.timelinediy {
  margin-top: 30px;
  padding-left: 150px;
  position: relative;
}

.timelinediy .y-time {
  position: absolute;
  top: 0;
  left: -140px;
}

.timelinediy .el-timeline-item__timestamp.is-top {
  padding-top: 24px;
}

.timelinediy .t-title {
  position: absolute;
  top: 0;
  left: 20px;
}
</style>
